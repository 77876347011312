import { graphql } from "gatsby";
import Image from "gatsby-image";
import { RichText } from "prismic-reactjs";
import Layout from "../components/Layout";
import htmlSerializer from "../utils/html-serializer";

export const query = graphql`
  query ToijalaFarmQuery($uid: String!, $lang: String!) {
    prismic {
      toijala_farm(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        description
        pictures {
          picture
          pictureSharp {
            id
            picture: childImageSharp {
              main: fluid {
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
      history(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        description
        pictures {
          picture
          pictureSharp {
            id
            picture: childImageSharp {
              main: fluid {
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
      services(uid: $uid, lang: $lang) {
        _meta {
          lang
          uid
        }
        name
        description
        pictures {
          picture
          pictureSharp {
            id
            picture: childImageSharp {
              main: fluid {
                src
                srcSet
                aspectRatio
                sizes
              }
            }
          }
        }
      }
    }
  }
`;

const Basic = (props: any) => {
  const data =
    props.data.prismic.toijala_farm ||
    props.data.prismic.history ||
    props.data.prismic.services;

  if (!data) return null;

  const allPictures = data.pictures.filter(
    (picture: any) => picture?.pictureSharp?.picture?.main
  );

  return (
    <Layout lang={data._meta.lang}>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <div>
          <RichText render={data.name} />
          <RichText render={data.description} htmlSerializer={htmlSerializer} />
        </div>
        <div>
          {allPictures.map((picture: any, index: number) => (
            <div className="pb-4">
              <Image
                key={`picture-${index}`}
                fluid={picture.pictureSharp.picture.main}
              />
            </div>
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Basic;
